@import "./variables.less";

.header_bar {
  position: sticky;
  top: 0;
  z-index: 1000;
  .ant-layout-header {
    height: 64px;
    width: calc(100% - 3rem);
    max-width: 84rem;
    margin: 1rem auto 0;
    border-radius: 0.375rem;
    padding: 0 1.5rem !important;
    background-color: #ffffff !important;
    box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header_gt {
      display: flex;
      align-items: center;
      .add_btn {
        margin-right: 1.5rem;
      }
    }
  }
}