@import "./variables.less";

.projet_item {
  background-color: #fff;
  border-radius: .375rem;
  padding: 1.5rem;
  margin-bottom: .75rem;

  .pi_card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: .75rem;
    border-bottom: .0625rem solid #dbdade;

    .pi_card_header_lt {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 3.5rem;
        height: 2.625rem;
        object-fit: cover;
        border-radius: .25rem;
      }

      .pi_title {
        font-size: 1.0625rem;
        font-weight: 600;
        color: #4b4b4b;
        // margin-left: 1.25rem;
      }

      .pi_state {
        font-size: .75rem;
        font-weight: bold;
        border-radius: 0.25rem;
        padding: .25rem .625rem;
        margin-left: .9375rem;
        user-select: none;
        cursor: pointer;
        &.success {
          color: #28c76f;
          background-color: #dff7e9;
        }
        &.warning {
          color:#fcc10e;
          background-color: #fff9bf;
        }
        &.danger {
          color: #ea5455;
          background-color: #ffe3e3;
        }
      }
    }

    .pi_card_header_rt {
      dl {
        display: flex;
        align-items: center;

        .cdTitle {
          font-size: .875rem;
          color: #666;
          margin-left: .625rem;
        }

        .last_update {
          display: flex;
          align-items: center;
          margin-right: 1.25rem;

          .cardIc {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.375rem;
            height: 2.375rem;
            border-radius: 100px;
            background-color: @primary-color-18;

            .anticon {
              font-size: 0.9375rem;
              color: @primary-color;
            }
          }
        }

        .hours_ago {
          margin-right: .9375rem;

          span {
            display: inline-block;
            width: 2.125rem;
            height: 1.5rem;
            background-color: @primary-color;
            border-radius: .25rem;
            text-align: center;
            line-height: 1.5rem;
            color: #fff;
          }
        }

        .dropShow {
          display: flex;
          align-items: center;

          .dropBtn {
            font-size: 1.125rem;
            font-weight: bold;
            color: #4b4b4b;
          }
        }
      }
    }
  }

  .pi_card_bottom {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;

      li {
        flex-basis: calc(25% - .9375rem);
        width: 100%;
        margin-top: 1.25rem;
        background-color: #fff;
        border-radius: .375rem;
        border: .0625rem solid #c8c8c8;

        dl {
          padding: 1.5625rem 1.5rem;
        }

        .cmDl {
          position: relative;
          .cmIc {
            font-size: 1.25rem;
            height: 2.125rem;
          }

          dd {
            b {
              display: block;
              font-size: 1.375rem;
              font-weight: 600;
              color: #4b4b4b;
              margin-top: .3125rem;
              line-height: 1.9375rem;
            }

            span {
              display: block;
              margin-top: .3125rem;
              color: #7b7b7b;
              font-weight: 300;
            }
          }
          .operate {
            position: absolute;
            right: 1.5rem;
            bottom: 1.5625rem;
            color: @primary-color;
            cursor: pointer;
          }
          .pi_progress {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translate(0, -50%);
          }
        }

        .imDl {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          dd {
            display: flex;
            flex-direction: column;
            align-items: center;

            .imTitle {
              font-size: .875rem;
              color: #4b4b4b;
            }

            .btn_view {
              width: 100%;
              background-color: #FAD871;
              color: #333333;
              border: unset;
            }
            .ant-btn {
              &+.ant-btn {
                margin-top: .75rem;
              }
              span {
                font-size: .875rem;
              }
            }
          }
        }
      }
    }
  }
}

.cp_tab {
  display: flex;
  align-items: center;
  user-select: none;

  .cp_tab_it {
    min-width: 5.625rem;
    height: 2.625rem;
    border-radius: .375rem;
    padding: 0 1.25rem;
    font-size: 14px;
    font-weight: 500;
    color: #6F6B7D;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: #F2F1F5;
    }

    &.cp_tab_selected {
      background-color: @primary-color;
      color: #fff;
    }

    .cp_tab_icon {
      font-size: 20px;
      margin-right: .5rem;
    }
  }
}


/*响应式媒体查询*/
@media (max-width: 1200px) {
  .projet_item {
    .pi_card_bottom ul li {
      flex-basis: calc(50% - .625rem);
    }

    .pi_card_bottom ul li:nth-child(4) {
      order: 1;
    }

    .pi_card_bottom ul li:last-child {
      order: 2;
    }
  }
}

@media (max-width: 768px) {
  .projet_item {
    .pi_card_bottom ul li {
      flex-basis: 100%;
    }

    .pi_card_header {
      align-items: normal;
      flex-direction: column;
      justify-content: normal;

      .pi_card_header_rt {
        margin-top: 1.25rem;

        dl {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .dropShow {
            margin-left: auto;
          }
        }

      }
    }

  }
}

.ant-modal-body {
  .ant-checkbox-wrapper {
    margin: 0 !important;

    span {
      color: #ea5455;
    }
  }
  .ant-checkbox-wrapper{
    margin: 1rem;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-input,
  .ant-checkbox:hover .ant-checkbox-checked {
    background-color: @primary-color;
    border-color: @primary-color;
    font-weight: 600;
  }


  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
    border-color: @primary-color;
  }

  .ant-checkbox-checked .ant-checkbox-input {
    background-color: @primary-color;
    border-color: @primary-color;
  }

}

.foot_row {
  .foot_col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .foot_col:first-child {

    .ant-btn-default,
    .ant-btn-test {
      width: 88%;
      color: #fff;
      background: #ea5455;
      border-color: #ea5455;
    }
  }

  .foot_col:last-child {
    .ant-btn-default{
      width: 88%;
      color: #4b465c;
      background-color: #dfdfe3;
      border-color: #dfdfe3;
    }
  }
}

// 文件预览
.file_view {
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .fv_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    .anticon-close-circle {
      color: #fff;
      font-size: 2rem;
      position: absolute;
      top: 1rem;
      right: 2.5rem;
      cursor: pointer;
    }
  }
  .loading {
    font-size: 6.25rem;
    color: #FFFFFF;
  }
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 12rem);
    height: calc(100% - 4rem);
  }
}

@media (max-width: 768px) {
  .file_view {
    display: none;
  }
}

// 文件上传
.cp_upload {
  .cp_upload_inner {
    padding: 1.25rem 0!important;
  }
  .ant-upload-text {
    font-size: 1.375rem!important;
    color: #333!important;
    font-weight: bold;
    margin: .625rem 0!important;
  }
  .upLoadic {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 3.125rem;
    }
  }
  .cp_card_des {
    color: #A5A3AE;
    margin: 1rem 0;
    font-size: 1rem;
  }
}

// 写作助手
.chat_helper {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 0 .625rem;
  // align-items: center;
  .chat_lt {
    display: flex;
    flex-wrap: wrap;
    max-height: 5rem;
    overflow: hidden;
    margin-top: -.375rem;
    .chat_helper_item {
      height: 2.125rem;
      padding: .3125rem .625rem .3125rem .375rem;
      margin-left: .375rem;
      display: flex;
      align-items: center;
      border-radius: .375rem;
      border: 1px solid #D8D8D8;
      margin-top: .375rem;
      user-select: none;
      cursor: pointer;
      &:hover {
        color: @primary-color;
        border: 1px solid @primary-color;
        .chat_emoji {
          animation: emoji-swing 1s ease 1 !important;
          animation-fill-mode: forwards !important;
        }
      }
      .chat_emoji {
        width: 1.5rem;
        height: 1.5rem;
        // line-height: 1.375rem;
        margin-right: .125rem;
        transform: scale(1.2);
      }
    }
  }
  .chat_gt {
    .chat_h_more_btn {
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      border-radius: 1.125rem;
      background: #FFBD5A;
      padding: 0 1rem;
      white-space: nowrap;
      cursor: pointer;
      .chat_h_more_icon {
        margin-right: .25rem;
      }
    }
  }
}
.chat_h_more_dropdown {
  width: 20rem;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  border-radius: 1.125rem;
  background: #FFFFFF;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  .chat_h_more_title {
    font-size: .875rem;
    color: #333333;
  }
  .chat_h_more_main {
    margin-left: -0.375rem;
    min-height: 10rem;
    max-height: 15rem;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
    .chat_helper_d_item {
      height: 2.125rem;
      padding: .3125rem .625rem .3125rem .375rem;
      margin-left: .375rem;
      margin-top: 10px;
      display: flex;
      align-items: center;
      border-radius: .375rem;
      border: 1px solid #D8D8D8;
      cursor: pointer;
      position: relative;
      &:hover {
        color: @primary-color;
        border: 1px solid @primary-color;
        .chat_emoji {
          animation: emoji-swing 1s ease 1 !important;
          animation-fill-mode: forwards !important;
        }
        .chat_h_star_box {
          z-index: 1;
          padding: .125rem;
          height: unset;
          bottom: -0.125rem;
          transform: translate(0, 100%);
        }
      }
      .chat_emoji {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.375rem;
        margin-right: .125rem;
        transform: scale(1.2);
      }
      .chat_h_star_box {
        background-color: @primary-color-hex6-18;
        padding: 0 .375rem;
        height: 0;
        padding: 0;
        display: flex;
        align-items: center;
        border-radius: .375rem .375rem;
        justify-content: center;
        position: absolute;
        height: 0px;
        bottom: 0;
        right: 0;
        overflow: hidden;
        transition: all .3s;
        &.stared {
          color: #FFBD5A;
        }
      }
    }
  }
}
@keyframes emoji-swing {
  0% {
    transform: scale(1.2) rotate(0deg);
  }
  10% {
    transform: scale(1.4) rotate(10deg);
  }
  30% {
    transform: scale(1.5) rotate(-10deg);
  }
  50% {
    transform: scale(1.5) rotate(6deg);
  }
  70% {
    transform: scale(1.5) rotate(-4deg);
  }
  90% {
    transform: scale(1.5) rotate(2deg);
  }
  100% {
    transform: scale(1.5) rotate(0deg);
  }
}

@media ( max-width: 768px ) {
  .chat_helper {
    width: calc(100% - 2.625rem);
    margin: 0 1.5rem 0 1.125rem;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -100%);
    .chat_lt {
      overflow: auto;
      max-height: 4rem;
      margin-top: -.375rem;
      .chat_helper_item {
        height: 1.5rem;
        padding: 0 .625rem 0 .375rem;
        margin-left: .375rem;
        border-radius: .375rem;
        margin-top: .375rem;
        font-size: .875rem;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: @primary-color;
          border: 1px solid @primary-color;
          .chat_emoji {
            animation: emoji-swing 1s ease 1 !important;
            animation-fill-mode: forwards !important;
          }
        }
        .chat_emoji {
          width: 1.25rem;
          height: 1.25rem;
          // line-height: 1.375rem;
          margin-right: .125rem;
          transform: scale(1.2);
        }
      }
    }
    .chat_gt {
      .chat_h_more_btn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 2.25rem;
        height: 2.25rem;
        padding: 0;
        cursor: pointer;
        .chat_h_more_icon {
          margin-right: 0;
        }
        .chat_h_more_btn_text {
          display: none;
        }
      }
    }
  }

  .chat_h_more_dropdown {
    width: 20rem;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    border-radius: 1.125rem;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    .chat_h_more_title {
      font-size: .875rem;
      color: #333333;
    }
    .chat_h_more_main {
      margin-left: -0.375rem;
      min-height: 10rem;
      max-height: 15rem;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      .chat_helper_d_item {
        height: 2.125rem;
        padding: .3125rem .625rem .3125rem .375rem;
        margin-left: .375rem;
        margin-top: .375rem;
        display: flex;
        align-items: center;
        border-radius: .375rem;
        border: 1px solid #D8D8D8;
        cursor: pointer;
        position: relative;
        &:hover {
          color: @primary-color;
          border: 1px solid @primary-color;
          .chat_emoji {
            animation: emoji-swing 1s ease 1 !important;
            animation-fill-mode: forwards !important;
          }
          .chat_h_star_box {
            // padding-top: 20px;
            height: 2.125rem;
            top: -2.1875rem;
          }
        }
        .chat_emoji {
          width: 1.5rem;
          height: 1.5rem;
          line-height: 1.375rem;
          margin-right: .125rem;
          transform: scale(1.2);
        }
        .chat_h_star_box {
          background-color: @primary-color-hex6-18;
          width: 2.125rem;
          display: flex;
          align-items: center;
          border-radius: .375rem .375rem 0 0;
          justify-content: center;
          position: absolute;
          height: 0px;
          top: 0;
          right: 0;
          overflow: hidden;
          transition: all .3s;
        }
      }
    }
  }
}