@import "./variables.less";

.navigation {
  min-height: 100vh;
  .ant-layout-sider {
    height: 100vh;
    max-height: 100vh;
    box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
    user-select: none;
    // 修改菜单样式
    .ant-menu {
      
      .ant-menu-submenu-title {
        margin: .125rem .875rem;
        width: calc(100% - 1.75rem);
        border-radius: .375rem !important;
        &:active , &:hover{
          background: #f8f8f8 !important;
        }
      }
      .ant-menu-sub.ant-menu-inline {
        background: #fff !important;
      }
      .ant-menu-item {
        margin: .125rem .875rem;
        width: calc(100% - 1.75rem);
        border-radius: .375rem !important;
        &:active , &:hover{
          background: #f8f8f8 !important;
        }
      }
      
      // 选中
      .ant-menu-submenu-selected {
        &>.ant-menu-submenu-title {
          color: rgba(0,0,0,0.88) !important;
          font-weight: 600;
          background: #f8f8f8 !important;
        }
        .ant-menu-item-selected {
          background: linear-gradient(72.47deg,@primary-color 22.16%,@primary-color-70 76.47%) !important;
          box-shadow: 0 2px 6px #7367f07a;
          color: #fff !important;
        }
      }
      .ant-menu-item-selected {
        color: rgba(0,0,0,0.88) !important;
        font-weight: 600;
        background: #f8f8f8 !important;
      }
      .leadIc {
        font-size: 1.125rem;
      }
    }
    .logo_box {
      height: 64px;
      margin: 0 0.875rem 0 1rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon_box {
        overflow: hidden;
        cursor: pointer;
        
        .logo {
          width: 160px;
          max-width: 160px;
        }
      }
      .nav_close {
        cursor: pointer;
      }
      &.collapsed {
        max-width: 40px;
        .icon_box {
          max-width: 28px;
        }
        .nav_close {
          display: none;
        }
      }
    }
  }
  .ant-layout {
    background: unset;
  }
}

@media (max-width: 1200px)  {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    transition: all .5s;
    &[open]{
      width: 100vw;
      background: #4b465c7f;
    }
  }
}