@import "./variables.less";

/* global.css */

/* 重置 body 和 html 元素的样式 */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: .9375rem;
  line-height: 1.47;
  min-height: 100vh;
}
/*部分标签全局样式控制*/
*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
}
ul,ol {
	list-style-type: none;
}
img {
	max-width: 100%;
	height: auto;
}
i {
  font-style:normal;
}


/* 设置全局链接的颜色和样式 */
a {
  color: @primary-color;
  text-decoration: none;
  cursor: pointer;
}

// 滚动条样式
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: .3125rem;
  height: .3125rem;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 1rem;
  background: #ccc;
}
*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  display: none;
}

/* 其他全局样式规则 */
/* ... */
.ant-layout {
  background-color: #F8F7FA !important;
}
.ant-btn {
  padding: .6rem 1.25rem;
  font-size: .9375rem;
  line-height: initial;
  height: unset;
  border-radius: .375rem;
  font-weight: 500;
  &:disabled {
    color: #B7B7B7 !important;
    border-color: #D9D9D9 !important;
    background-color: #F5F5F5 !important;
  }
  &.ant-btn-primary {
    color: #fff;
    background-color: @primary-color;
    border-color: @primary-color;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    &:hover{
      color: #fff;
      background-color: @primary-color;
      border-color: @primary-color;
    }
    &:active {
      scale: .98;
      background-color: @primary-color;
      border-color: @primary-color;
    }
  }
}

.ant-btn-lg {
  padding: .8rem 1.5rem;
  font-size: 1rem;
}

.clr_danger {
  background-color: #fce5e6 !important;
  color: #ea5455 !important;
}
.clr_primary {
  background-color: #eae8fd !important;
  color: #854DFF !important;
}
.clr_success {
  background-color: #dff7e9 !important;
  color: #28c76f !important;
}
.clr_secondary {
  background-color: #f2f2f3 !important;
  color: #a8aaae !important;
}

// 头像下拉框
.header_dropdown {
  margin: -12px;
  padding: .25rem 0;
  width: 14rem;
  color: #5D596C;
  font-size: .9375rem;
  .hd_header {
    display: flex;
    margin-bottom: -.25rem;
    .person_box {
      margin-right: 1.25rem;
    }
    .person_name {
      font-weight: 600;
    }
  }
  .hd_line {
    border-top: 1px solid #dbdade;
    margin: .5rem 0;
  }
}
// 下拉框按钮
.dropdown_btn {
  line-height: 1.375;
  width: calc(100% - 1rem);
  margin: 0.25rem 0.5rem;
  padding: .42rem 1rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: @primary-color;
    background-color: @primary-color-18;
  }
  .anticon {
    font-size: 1.125rem;
    margin-right: .5rem;
  }
}
// 头像框
.person_box {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EBF4FF;
  color: @primary-color;
  // color: #9DB5F8;
  user-select: none;
  cursor: pointer;
}